import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Select } from 'antd'

const FilterFacebodyStudent = ({
  filterValue,
  setFilterValue
}) => {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [tempfilterValue, setTempFilterValue] = useState({})
  const [categoryFilter, setCategoryFilter] = useState([])
  const [collegeFilter, setCollegeFilter] = useState([])
  const [majorFilter, setMajorFilter] = useState([])
  const [remarkFilter, setRemarkFilter] = useState([])

  useEffect(() => {
    callStudentFilter()
    getCategoryFilter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid])

  const getCategoryFilter = () => {
    axios.post('/p/facebody/category/list/get', { 
      merchant_oid: merchantOid
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryFilter(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  const callStudentFilter = (facebody_category_oid, college) => { 
    axios.post('/p/facebody/student/filter/get', { 
      merchant_oid: merchantOid,
      facebody_category_oid: facebody_category_oid,
      college: college
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setCollegeFilter(response.data.colleges)
          setMajorFilter(response.data.majors)
          setRemarkFilter(response.data.remarks)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }


  let categoryItems = [
    {  
      value: '', 
      label: '全部'
    },
    ...categoryFilter.map((item) => (
      {
        value: item.oid,
        label: item.name
      }
    ))
  ]

  let collegeItems = [
    {  
      value: '', 
      label: '全部'
    },
    ...collegeFilter.map((item) => (
      {
        value: item,
        label: item
      }
    ))
  ]

  let majorItems = [
    {  
      value: '', 
      label: '全部'
    },
    ...majorFilter.map((item) => (
      {
        value: item,
        label: item
      }
    ))
  ]

  let remarkItems = [
    {  
      value: '', 
      label: '全部'
    },
    ...remarkFilter.map((item) => (
      {
        value: item,
        label: item
      }
    ))
  ]

  let photoItems = [
    {  
      value: 0, 
      label: '全部'
    },
    {
      value: 1,
      label: '已上传'
    },
    {
      value: 2,
      label: '未上传'
    }
  ]

  let statusItems = [
    {  
      value: 0, 
      label: '全部'
    },
    {
      value: 1,
      label: '未核验'
    },
    {
      value: 2,
      label: '核验通过'
    },
    {
      value: 3,
      label: '核验未通过'
    }
  ]

  const clickCategoryFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, category: value, college: '', major: ''})
    callStudentFilter(value)
  }

  const clickCollageFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, college: value, major: '' })
    callStudentFilter(tempfilterValue.category, value)
  }

  const clickMajorFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, major: value })
  }

  const clickRemarkFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, remark: value })
  }

  const clickPhotoFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, photo: value })
  }

  const clickStatusFilter = (value) => {
    setTempFilterValue({ ...tempfilterValue, status: value })
  }

  const clickSubmitFilter = () => {
    setFilterValue({ ...filterValue, ...tempfilterValue })
  }

  const clickResetFilter = () => {
    setFilterValue({ ...filterValue, category: '', college: '', major: '', remark: '', photo: 0, status: 0 })
    callStudentFilter()
    setTempFilterValue({})
  }

  return (
    <div className='public-conditions'>
      <div className='public-conditions-row'>
        <div className='public-condition'>
          <div className='public-condition-label'>选择分类：</div>
          <Select
            value={tempfilterValue.category}
            placeholder="请选择"
            options={categoryItems}
            style={{width: '220px'}}
            onChange={(value) => clickCategoryFilter(value)}
          />
        </div>

        <div className='public-condition'>
          <div className='public-condition-label'>选择学院：</div>
          <Select
            value={tempfilterValue.college}
            placeholder="请选择"
            options={collegeItems}
            style={{width: '220px'}}
            onChange={(value) => clickCollageFilter(value)}
          />
        </div>

        <div className='public-condition'>
          <div className='public-condition-label'>选择专业：</div>
          <Select
            value={tempfilterValue.major}
            placeholder="请选择"
            options={majorItems}
            style={{width: '220px'}}
            onChange={(value) => clickMajorFilter(value)}
          />
        </div>
      </div>

      <div className='public-conditions-row'>
        <div className='public-condition'>
          <div className='public-condition-label'>选择备注：</div>
          <Select
            value={tempfilterValue.remark} 
            placeholder="请选择"
            options={remarkItems}
            style={{width: '220px'}}
            onChange={(value) => clickRemarkFilter(value)}
          />
        </div>

        <div className='public-condition'>
          <div className='public-condition-label'>录取照片：</div>
          <Select
            value={tempfilterValue.photo} 
            placeholder="请选择"
            options={photoItems}
            style={{width: '220px'}}
            onChange={(value) => clickPhotoFilter(value)}
          />
        </div>

        <div className='public-condition'>
          <div className='public-condition-label'>核验状态：</div>
          <Select
            value={tempfilterValue.status} 
            placeholder="请选择"
            options={statusItems}
            style={{width: '220px'}}
            onChange={(value) => clickStatusFilter(value)}
          />
        </div>
      </div>

      <div className='public-conditions-buttons'>
        <div className='public-conditions-button1' onClick={() => { clickSubmitFilter() }}>筛选</div>
        <div className='public-conditions-button2' onClick={() => { clickResetFilter() }}>重置</div>
      </div>
    </div>
  )
}

export default FilterFacebodyStudent
